var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0"},[_c('TarjetaDatos',{staticClass:"pa-3",attrs:{"header":"","titulo":"Detalles","icon":"eye","datos":[
      { nombre: 'Tipo', valor: _vm.cont.tipo },
      {
        nombre: 'Fecha cont',
        valor: _vm.parseDate(_vm.cont.fechacont, false, true, true),
      },
      {
        nombre: 'Fecha registro',
        valor: _vm.parseDate(_vm.cont.fechaRegistro, false, true, true),
      },
      { nombre: 'Respondida', valor: _vm.textoResp },
      { nombre: 'Resumen', valor: _vm.cont.resumen } ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }